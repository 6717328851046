import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { TypeInput, ValidationTextField } from '@bloomays-lib/ui.shared';
import { TitlePart } from '@bloomays-lib/ui.shared';
import { Button } from '@bloomays-lib/ui.shared';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import { useEffect, useState } from 'react';
import { JobTalent, ISkill, ITag, IPeriod } from '@bloomays-lib/types.shared';
import DatePicker from '../atoms/DatePicker';
import { ItemTalentLevel, Switch } from '@bloomays-lib/ui.shared';
import { AutoCompleteTag } from '@bloomays-lib/ui.shared';
import React from 'react';
import uniqBy from 'lodash/uniqBy';
import { useLazyQuery } from '@apollo/client';
import { FIND_JOBS_BY_LABEL, FIND_SKILLS_BY_LABEL } from '@bloomays-lib/adapter.api-talent';
import { capitalize, convertDateToIso, isDate } from '@bloomays-lib/utils.shared';

export type XPEducationEditionProps = {
  item?: JobTalent;
  deleteXP: (id?: string) => Promise<void>;
  upsert: (e: any) => any;
  loading: boolean;
  type: 'XP' | 'education';
  itemList?: string[];
  itemListSkill?: string[];
};

const XPEducationEdition = ({
  item,
  loading,
  upsert,
  type,
  itemList,
  itemListSkill,
  deleteXP,
}: XPEducationEditionProps) => {
  const { t } = useTranslation(['talentSheet', 'languages', 'random']);
  const [TMPItem, setTMPItem] = useState(item);
  const [hasChanged, setHasChanged] = useState(false);
  const [skills, setSkills] = useState(item?.skills);
  const [jobTitles, setJobTitles] = useState(item?.jobTitle);
  const [skillTag, setSkillTag] = useState<ITag[]>([]);
  const [jobTitleTag, setJobTitleTag] = useState<ITag[]>([]);

  const [findManySkillsFromLabel, { loading: loadingSkill }] = useLazyQuery<{
    findManySkillsFromLabel: ISkill[];
  }>(FIND_SKILLS_BY_LABEL, {
    context: { clientName: 'api.talents' },
  });

  const [findManyJobTitlesFromLabel, { loading: loadingJobTitle }] = useLazyQuery<{
    findManyJobTitleFromLabel: ISkill[];
  }>(FIND_JOBS_BY_LABEL, {
    context: { clientName: 'api.talents' },
  });

  const collectSkills = React.useCallback(
    async (skill: string) => {
      setHasChanged(true);
      return (
        (
          await findManySkillsFromLabel({
            variables: { label: skill },
            context: { clientName: 'api.talents' },
          })
        ).data?.findManySkillsFromLabel.map((skill) => {
          return { value: skill.label, count: 1 };
        }) || []
      );
    },
    [findManySkillsFromLabel],
  );

  const collectJobTitles = React.useCallback(
    async (jobTitle: string) => {
      setHasChanged(true);
      return (
        (
          await findManyJobTitlesFromLabel({
            variables: { label: jobTitle, iso: true },
            context: { clientName: 'api.talents' },
          })
        ).data?.findManyJobTitleFromLabel.map((jobTitle) => {
          return { value: jobTitle.label, count: 1 };
        }) || []
      );
    },
    [findManyJobTitlesFromLabel],
  );

  useEffect(() => {
    setTMPItem(item);
    setSkills(item?.skills);
    if (item?.skills) {
      setSkillTag(
        item?.skills?.map((skill) => {
          return { value: skill.label, count: 1 };
        }),
      );
    }
  }, [item]);

  useEffect(() => {
    setTMPItem(item);
    setJobTitles(item?.jobTitle);
    if (item?.jobTitle) {
      const tagCleaned = item?.jobTitle?.map((job) => {
        return { value: job.label, count: 1 };
      }) as ITag[];
      setJobTitleTag(tagCleaned);
    }
  }, [item]);

  return (
    <StyledXPEducationEdition>
      <StyledContainerTitle>
        <TitlePart variant={'heading5'} textTitle={t('addXP')} />
      </StyledContainerTitle>
      <AutoCompleteTag
        collectTags={collectJobTitles}
        setTag={setJobTitleTag}
        loading={loadingJobTitle}
        label={`${t('addJobTitle')}*`}
        placeholder={`${t('addJobTitle')}*`}
      />
      <StyledContainerSkill>
        {jobTitles?.map((job) => {
          return (
            <ItemTalentLevel
              skill={job}
              clickable
              onClickCross={(e) => {
                setJobTitleTag(jobTitleTag.filter((job) => job.value !== e.label));
                setJobTitles(jobTitles.filter((job) => job.label !== e.label));
                setHasChanged(true);
              }}
            />
          );
        })}
      </StyledContainerSkill>
      <ValidationTextField
        label={t('nameCorpo')}
        required
        onChange={(e: any) => {
          setTMPItem({ ...TMPItem, name: e.target.value });
          setHasChanged(true);
        }}
        value={TMPItem?.name}
        type={TypeInput.text}
        placeholder=""
      />
      <StyledCurrentJob>
        <Switch
          checked={TMPItem?.current}
          onSwitch={(e: any) => {
            setTMPItem({ ...TMPItem, current: e.target.checked });
            setHasChanged(true);
          }}
        />
        <SingleLineText text={t('isCurrentJob')} />
      </StyledCurrentJob>
      <StyledContainerDate>
        <StyledContainerOneDate>
          <SingleLineText variant={'body1Regular'} text={t('beginDate', { ns: 'random' })} />
          <DatePicker
            handleChange={(e: Date | null) => {
              if (!e || !isDate(e)) {
                return;
              }
              setHasChanged(true);
              setTMPItem({
                ...TMPItem,
                period: {
                  ...TMPItem?.period,
                  startDate: convertDateToIso(e) as string,
                },
              });
            }}
            label={`${capitalize(t('month', { ns: 'random' }))} & ${capitalize(t('year', { ns: 'random' }))}*`}
            views={['month', 'year']}
            maxDate={TMPItem?.period?.endDate ? new Date(TMPItem?.period?.endDate) : undefined}
            openTo={'month'}
            value={TMPItem?.period?.startDate ? new Date(TMPItem?.period?.startDate) : null}
          />
        </StyledContainerOneDate>
        <StyledContainerOneDate>
          <SingleLineText variant={'body1Regular'} text={t('endDate', { ns: 'random' })} />
          <DatePicker
            handleChange={(e: Date | null) => {
              setHasChanged(true);
              const endDate = !e || !isDate(e) ? undefined : (convertDateToIso(e) as string);
              setTMPItem({
                ...TMPItem,
                period: {
                  ...(TMPItem?.period as IPeriod),
                  endDate: endDate,
                },
              });
            }}
            views={['month', 'year']}
            disabled={TMPItem?.current}
            minDate={TMPItem?.period?.startDate ? new Date(TMPItem?.period?.startDate) : undefined}
            openTo={'month'}
            label={`${capitalize(t('month', { ns: 'random' }))} & ${capitalize(t('year', { ns: 'random' }))}`}
            value={TMPItem?.period?.endDate ? new Date(TMPItem?.period?.endDate) : null}
          />
        </StyledContainerOneDate>
      </StyledContainerDate>
      <ValidationTextField
        label={t('description')}
        onChange={(e: any) => {
          setTMPItem({ ...TMPItem, description: e.target.value });
          setHasChanged(true);
        }}
        value={TMPItem?.description}
        multiline
        minRows={3}
        type={TypeInput.text}
        placeholder=""
      />
      <AutoCompleteTag
        collectTags={collectSkills}
        setTag={setSkillTag}
        loading={loadingSkill}
        label={t('addSkill')}
        placeholder={t('addSkill')}
      />
      <StyledContainerSkill>
        {skills?.map((skill) => {
          return (
            <ItemTalentLevel
              skill={skill}
              clickable
              onClickCross={(e) => {
                setSkillTag(skillTag.filter((skill) => skill.value !== e.label));
                setSkills(skills.filter((skill) => skill.label !== e.label));
                setHasChanged(true);
              }}
            />
          );
        })}
      </StyledContainerSkill>
      <StyledSaveDiv>
        {TMPItem?.id && (
          <StyledDeleteButton
            textButton={t('delete', { ns: 'random' })}
            onClick={async () => {
              await deleteXP(TMPItem?.id);
            }}
            disable={loading}
          />
        )}
        <Button
          textButton={t('save', { ns: 'random' })}
          onClick={async () => {
            const tags = skillTag?.map((element) => {
              return { label: element.value };
            });
            const existingSkills = skills?.map((element) => {
              return { label: element.label };
            });
            const skillsToAdd = [...tags, ...(existingSkills || [])];

            const tagsJob = jobTitleTag?.map((element) => {
              return { label: element.value };
            });
            const existingJobs = jobTitles?.map((element) => {
              return { label: element.label };
            });
            const jobsToAdd = [...tagsJob, ...(existingJobs || [])];
            setHasChanged(false);
            await upsert({
              ...TMPItem,
              skills: uniqBy(skillsToAdd, 'label'),
              jobTitle: uniqBy(jobsToAdd, 'label'),
            });
          }}
          disable={loading || (jobTitleTag.length === 0 && !jobTitles) || !TMPItem?.name || !TMPItem?.period?.startDate}
        />
      </StyledSaveDiv>
    </StyledXPEducationEdition>
  );
};

export default XPEducationEdition;
export { XPEducationEdition };

const StyledXPEducationEdition = styled('div')(
  ({ theme }) => `
    background-color: ${theme.palette.grey[200]};
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 4px 14px;
    min-width: 720px;
  `,
);

const StyledContainerTitle = styled('div')(
  () => `
    display: flex;
    justify-content: flex-start;
    text-align: left;
    margin-bottom: 30px;
    `,
);

const StyledSaveDiv = styled('div')(
  () => `
    display: flex;
    justify-content: space-between;
    `,
);

const StyledCurrentJob = styled('div')(
  () => `
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    `,
);

const StyledContainerDate = styled('div')(
  () => `
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    gap: 8px;
    padding: 20px 0;
    align-items: center;
    `,
);

const StyledContainerOneDate = styled('div')(
  () => `
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    gap: 10px;
    `,
);

const StyledContainerSkill = styled('div')(
  () => `
  display: flex;
  flex-flow: row wrap; 
  gap: 8px;
  padding: 2px;
  padding-bottom: 30px;
  `,
);

const StyledDeleteButton = styled(Button)(
  ({ theme }) => `
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  background-color: ${theme.palette.grey[500]};
  color: ${theme.palette.paper.white};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
  &:hover {
    cursor: 'pointer';
    color: ${theme.palette.grey[500]};
    background-color: ${theme.palette.paper.white};
    transition: 'ease 0.3s';
  }
  `,
);
